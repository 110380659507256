import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, InjectionToken } from '@angular/core';

declare const grecaptcha: ReCaptchaV2.ReCaptcha;

export const RECAPTCHA_V3_SITE_KEY = new InjectionToken<string>(
  'recaptcha-v3-site-key',
);

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private loaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(RECAPTCHA_V3_SITE_KEY) private readonly siteKey: string,
  ) {
    this.load();
  }

  private load(): void {
    if (this.loaded) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;

    this.document.head.appendChild(script);

    this.loaded = true;
  }

  public async getToken(action: string): Promise<string> {
    if (!this.siteKey) {
      throw new Error('Recaptcha site key is not set.');
    }

    return new Promise<string>((resolve) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(this.siteKey!, { action })
          .then((token) => resolve(token));
      });
    });
  }
}
