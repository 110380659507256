@if (user) {
  <app-account-header></app-account-header>
  <div class="mx-auto grid max-w-2xl gap-y-4 p-2">
    <fieldset>
      <legend>Account Details</legend>
      <div class="grid gap-y-4">
        <div class="grid gap-y-2">
          <div class="flex items-center justify-between">
            <span class="text-lg font-medium">Email Address</span>
            @if (user.isEmailVerified) {
              <div class="flex items-center justify-end">
                <span>Verified</span>
                &nbsp;
                <mat-icon color="primary">done</mat-icon>
              </div>
            } @else {
              <span>Not Verified</span>
            }
          </div>
          @if (!user.isEmailVerified) {
            <button
              mat-button
              color="primary"
              (click)="requestEmailVerificationEmail()"
              [disabled]="isRequestingVerification"
            >
              @if (emailRecentlySent) {
                Resend Verification Email
              } @else {
                Send Verification Email
              }
            </button>
          }
        </div>
        <div class="grid gap-y-2">
          <div class="flex items-center justify-between">
            <span class="text-lg font-medium">Multi-factor Authentication</span>
            @if (user.isMfaEnabled) {
              <div class="flex items-center justify-between">
                <span>Enabled</span>
                &nbsp;
                <mat-icon color="primary">done</mat-icon>
              </div>
            } @else {
              <span>Not Enabled</span>
            }
          </div>

          @if (user.isMfaEnabled) {
            <!-- <button mat-button color="primary">Disable</button> -->
          } @else {
            <button
              mat-button
              mat-small-
              color="primary"
              (click)="openAddMultifactorAuthenticationDialog()"
            >
              Set Up
            </button>
          }
        </div>
      </div>
    </fieldset>
  </div>
}
