<div class="flex h-full w-full items-center justify-center text-center">
  <div class="grid w-[32rem] max-w-full">
    <div
      class="branding-background flex w-full items-center justify-center rounded-t py-2"
    >
      <img src="/assets/grackle.svg" class="logo" alt="Gracklesoft Logo" />
    </div>
    <div class="grid gap-y-2 bg-white p-4">
      <mat-tab-group dynamicHeight animationDuration="0ms">
        <mat-tab label="Login" [disabled]="isLoading">
          <ng-template matTabContent>
            <div class="w-full py-3">
              <app-login (loadingChanged)="loadingChanged($event)"></app-login>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Register" [disabled]="isLoading">
          <ng-template matTabContent>
            <div class="w-full py-3">
              <app-register
                (loadingChanged)="loadingChanged($event)"
              ></app-register>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
      <mat-divider></mat-divider>
      <a mat-raised-button [href]="googleAuthorizeUrl" [disabled]="isLoading">
        <mat-icon svgIcon="sign-in-with-google"></mat-icon>
        Sign In With Google
      </a>
    </div>
    <div class="rounded-b bg-white">
      @if (isLoading) {
        <mat-progress-bar
          mode="indeterminate"
          color="primary"
          class="absolute bottom-0"
        ></mat-progress-bar>
      } @else {
        <div class="reserved-space"></div>
      }
    </div>
  </div>
</div>
