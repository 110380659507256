import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { MatButtonModule } from '@angular/material/button';
import { LoginService } from 'src/app/services/login.service';
import { AppConfigurationStoreService } from 'src/app/stores/app-configuration-store.service';

@Component({
  selector: 'app-account-header',
  templateUrl: './account-header.component.html',
  styleUrl: './account-header.component.scss',
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    TruncatePipe,
  ],
})
export class AccountHeaderComponent {
  public user!: User;
  public stratosheetUrl!: string;

  constructor(
    private appConfigurationStore: AppConfigurationStoreService,
    private loginService: LoginService,
    private router: Router,
  ) {
    if (!this.appConfigurationStore.appConfiguration.user) {
      this.router.navigate(['/']);
      return;
    }
    this.user = this.appConfigurationStore.appConfiguration.user;
    this.stratosheetUrl =
      this.appConfigurationStore.appConfiguration.stratosheetUrl;
  }

  public async logOut(): Promise<void> {
    await this.loginService.logOut();
    this.router.navigate(['/']);
  }
}
