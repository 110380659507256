import { __extends } from "tslib";
import { v4 as uuid } from '@lukeed/uuid';
import { addPageContext } from '../page';
import { CoreEventFactory } from '@segment/analytics-core';
export * from './interfaces';
var EventFactory = /** @class */function (_super) {
  __extends(EventFactory, _super);
  function EventFactory(user) {
    var _this = _super.call(this, {
      createMessageId: function () {
        return "ajs-next-".concat(Date.now(), "-").concat(uuid());
      },
      onEventMethodCall: function (_a) {
        var options = _a.options;
        _this.maybeUpdateAnonId(options);
      },
      onFinishedEvent: function (event) {
        _this.addIdentity(event);
        return event;
      }
    }) || this;
    _this.user = user;
    return _this;
  }
  /**
   * Updates the anonymousId *globally* if it's provided in the options.
   * This should generally be done in the identify method, but some customers rely on this.
   */
  EventFactory.prototype.maybeUpdateAnonId = function (options) {
    (options === null || options === void 0 ? void 0 : options.anonymousId) && this.user.anonymousId(options.anonymousId);
  };
  /**
   * add user id / anonymous id to the event
   */
  EventFactory.prototype.addIdentity = function (event) {
    if (this.user.id()) {
      event.userId = this.user.id();
    }
    if (this.user.anonymousId()) {
      event.anonymousId = this.user.anonymousId();
    }
  };
  EventFactory.prototype.track = function (event, properties, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.track.call(this, event, properties, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  EventFactory.prototype.page = function (category, page, properties, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.page.call(this, category, page, properties, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  EventFactory.prototype.screen = function (category, screen, properties, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.screen.call(this, category, screen, properties, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  EventFactory.prototype.identify = function (userId, traits, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.identify.call(this, userId, traits, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  EventFactory.prototype.group = function (groupId, traits, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.group.call(this, groupId, traits, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  EventFactory.prototype.alias = function (to, from, options, integrationsOptions, pageCtx) {
    var ev = _super.prototype.alias.call(this, to, from, options, integrationsOptions);
    addPageContext(ev, pageCtx);
    return ev;
  };
  return EventFactory;
}(CoreEventFactory);
export { EventFactory };
