<div class="dialog-form-grid">
  <h1 mat-dialog-title>Set Up Multi-Factor Authentication</h1>
  <mat-dialog-content>
    @if (isLoading) {
      <div class="flex h-full items-center justify-center">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
    } @else {
      @if (hasVerified) {
        <form [formGroup]="enrollFormGroup">
          <p class="flex items-center justify-between">
            <span>
              Your recovery code is:
              <span class="font-bold">{{ info.recoveryCode }}</span>
            </span>
            <button mat-icon-button (click)="copyRecoveryCode()">
              <mat-icon>content_copy</mat-icon>
            </button>
          </p>
          <p>
            Keep this code in a secure place. If you lose access to your
            authenticator application, this is the
            <span class="font-bold">only</span>
            way to recover your account.
          </p>
          <p>
            <mat-checkbox formControlName="acknowledgement">
              I have stored this code
            </mat-checkbox>
          </p>
        </form>
      } @else {
        <form [formGroup]="verifyFormGroup">
          <div>Scan the QR code below in your authenticator app.</div>

          <div class="flex justify-center">
            <img [src]="info.base64QrCode" alt="QR Code" />
          </div>

          <p>
            After you've scanned the code above, enter the 8 digit number from
            your authenticator application below and click Verify.
          </p>
          <mat-form-field class="w-full">
            <input
              type="text"
              matInput
              inputmode="numeric"
              formControlName="appCode"
              autocomplete="one-time-code"
            />
            @if (
              verifyFormGroup.controls.appCode.errors?.["minlength"] ||
              verifyFormGroup.controls.appCode.errors?.["maxlength"]
            ) {
              <mat-error>The code should be exactly 8 characters</mat-error>
            } @else if (verifyFormGroup.controls.appCode.errors?.["required"]) {
              <mat-error>The code is required</mat-error>
            } @else if (
              verifyFormGroup.controls.appCode.errors?.["invalidCode"]
            ) {
              <mat-error>
                The provided code is not valid or has expired
              </mat-error>
            }
          </mat-form-field>
        </form>
      }
    }
  </mat-dialog-content>
  <mat-dialog-actions class="flex" align="end">
    <button mat-raised-button mat-dialog-close class="flex-1">Cancel</button>
    @if (hasVerified) {
      <button
        type="submit"
        mat-raised-button
        color="primary"
        class="flex-1"
        [disabled]="isEnrolling || enrollFormGroup.invalid"
        (click)="enroll()"
      >
        Enable MFA
      </button>
    } @else {
      <button
        type="submit"
        mat-raised-button
        color="primary"
        class="flex-1"
        (click)="verify()"
        [disabled]="isVerifying"
      >
        Verify
      </button>
    }
  </mat-dialog-actions>
  @if (isEnrolling || isVerifying) {
    <mat-progress-bar
      mode="indeterminate"
      color="primary"
      class="absolute bottom-0"
    ></mat-progress-bar>
  } @else {
    <div class="reserved-space"></div>
  }
</div>
