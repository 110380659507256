<div class="background-image h-full w-full bg-cover bg-center">
  @if (isLoading) {
    <div class="flex h-full w-full items-center justify-center text-center">
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
    </div>
  } @else {
    <div class="public-layout h-full w-full">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  }
</div>
