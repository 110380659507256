import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  output,
  viewChild,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { RegistrationResult } from 'src/app/models/registrationResult';
import { RegisterService } from 'src/app/services/register.service';
import { passwordComplexityValidator } from 'src/app/validators/passwordComplexityValidator';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { APP_URLS, AppUrls } from 'src/app/models/app-urls';
import { RecaptchaService } from 'src/app/services/recaptcha.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class RegisterComponent
  implements AfterViewInit, AfterViewChecked, OnDestroy
{
  readonly emailInput = viewChild<ElementRef<HTMLInputElement>>('emailInput');
  public readonly loadingChanged = output<boolean>();
  public formGroup = this.formBuilder.group(
    {
      email: ['', [Validators.email, Validators.required]],
      password: [
        '',
        [
          Validators.minLength(8),
          Validators.required,
          passwordComplexityValidator,
        ],
      ],
    },
    { updateOn: 'submit' },
  );
  public isRegistering = false;
  public termsUrl: string;
  public privacyUrl: string;
  public showTermsError = false;

  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(
    @Inject(APP_URLS) appUrls: AppUrls,
    private formBuilder: FormBuilder,
    private registerService: RegisterService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private recaptchaService: RecaptchaService,
  ) {
    this.termsUrl = `${appUrls.publicUrl}/terms`;
    this.privacyUrl = `${appUrls.publicUrl}/privacy`;
  }

  ngOnDestroy(): void {
    this.snackBarRef?.dismiss();
  }

  ngAfterViewInit(): void {
    this.emailInput()?.nativeElement.focus();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public async register(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }

    const returnUrl =
      this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    this.formGroup.disable();
    this.isRegistering = true;
    this.loadingChanged.emit(true);
    try {
      const reCaptchaToken = await this.recaptchaService.getToken('register');

      const redirectUrl = await this.registerService.register(
        this.formGroup.controls.email.value!,
        this.formGroup.controls.password.value!,
        returnUrl,
        reCaptchaToken,
      );

      window.location.assign(redirectUrl);
    } catch (e) {
      this.formGroup.enable();
      this.isRegistering = false;
      this.loadingChanged.emit(false);
      if (e instanceof HttpErrorResponse && e.status === 422) {
        switch (e.error.registrationResult) {
          case RegistrationResult.EmailAlreadyRegistered:
            this.showError('That email address is already registered.');
            break;
          case RegistrationResult.InvalidPassword:
            this.showError(
              'The provided password did not meet the password requirements',
            );
            break;

          case RegistrationResult.Unknown:
            this.showError('An error occurred registering. Try again.');
            break;
        }
      } else if (e instanceof HttpErrorResponse && e.status === 429) {
        this.showError(
          'You have tried to register too many times. Please try again later.',
        );
      } else {
        this.showError('An error occurred registering. Try again.');
      }
    }
  }

  private showError(message: string) {
    this.snackBar.open(message, 'Dismiss');
  }
}
