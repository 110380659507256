import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { RecaptchaService } from 'src/app/services/recaptcha.service';
import { PasswordResetResult } from 'src/app/models/passwordResetResult';
import { PasswordResetService } from 'src/app/services/password-reset.service';
import { passwordComplexityValidator } from 'src/app/validators/passwordComplexityValidator';
import { MatButtonModule } from '@angular/material/button';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RouterLink,
  ],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public formGroup = this.formBuilder.group({
    password: [
      '',
      [
        Validators.minLength(8),
        Validators.required,
        passwordComplexityValidator(),
      ],
    ],
  });
  public isSaving = false;
  private token!: string;
  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private passwordResetService: PasswordResetService,
    private recaptchaService: RecaptchaService,
  ) {}

  ngOnInit(): void {
    const queryParamToken =
      this.activatedRoute.snapshot.queryParamMap.get('token');

    if (!queryParamToken) {
      this.router.navigate(['/forgot-password']);
    }

    this.token = queryParamToken!;
  }

  ngOnDestroy(): void {
    this.snackBarRef?.dismiss();
  }

  public async resetPassword(): Promise<void> {
    if (!this.formGroup.valid) {
      return;
    }

    const newPassword = this.formGroup.controls.password.value!;
    this.isSaving = true;
    this.formGroup.disable();
    try {
      const reCaptchaToken =
        await this.recaptchaService.getToken('reset_password');

      await this.passwordResetService.resetPassword(
        this.token,
        newPassword,
        reCaptchaToken,
      );
      this.snackBar.open(
        'Your password has been reset. You can now log in.',
        undefined,
        { duration: 5000 },
      );
      this.router.navigate(['/']);
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 422) {
        const result = e.error.result as PasswordResetResult;
        switch (result) {
          case PasswordResetResult.InvalidResetToken:
            this.snackBar
              .open(
                'Your reset token is not valid.',
                'Request A Password Reset',
              )
              .onAction()
              .subscribe(() => {
                this.router.navigate(['/forgot-password']);
              });
            break;
          case PasswordResetResult.AccountDisabled:
            this.showError(
              'Your account is disabled. Contact your account administrator.',
            );
            break;

          case PasswordResetResult.NewPasswordDoesNotMeetRequirements:
            this.showError(
              'Your password does not meet the complexity requirements.',
            );
            break;
          case PasswordResetResult.PasswordAlreadyUsed:
            this.showError(
              'The provided password has already been used. Enter a new password.',
            );
            this.formGroup.controls.password.setErrors({
              passwordAlreadyUsed: true,
            });
            break;
          case PasswordResetResult.PasswordResetTokenExpired:
            this.snackBar
              .open('Your reset token has expired.', 'Request A Password Reset')
              .onAction()
              .subscribe(() => {
                this.router.navigate(['/forgot-password']);
              });
            break;
          case PasswordResetResult.PasswordResetTokenAlreadyUsed:
            this.snackBar
              .open(
                'Your reset token has already been used.',
                'Request A Password Reset',
              )
              .onAction()
              .subscribe(() => {
                this.router.navigate(['/forgot-password']);
              });
            break;
          case PasswordResetResult.Unknown:
            this.showError(
              'An error occurred resetting your password. Try again.',
            );
            break;
        }
      } else if (e instanceof HttpErrorResponse && e.status === 429) {
        this.showError(
          'You have tried to reset your password too many times. Please try again later.',
        );
      } else {
        this.showError('An error occurred resetting your password. Try again.');
      }
    } finally {
      this.isSaving = false;
      this.formGroup.enable();
    }
  }

  private showError(message: string) {
    this.snackBarRef = this.snackBar.open(message, 'Dismiss');
  }
}
