import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountHeaderComponent } from '../account-header/account-header.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { User } from 'src/app/models/user';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ErrorSnackBarService } from 'src/app/services/error-snack-bar.service';
import { EmailVerificationService } from 'src/app/services/email-verification.service';
import { showAddMultifactorAuthenticationDialog } from '../add-multi-factor-authentication-dialog/add-multi-factor-authentication-dialog.component';
import { DialogOpenerService } from 'src/app/services/dialog-opener.service';
import { AppConfigurationStoreService } from 'src/app/stores/app-configuration-store.service';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-account-index',
  templateUrl: './account-index.component.html',
  styleUrl: './account-index.component.scss',
  imports: [
    AccountHeaderComponent,
    RouterModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
  ],
})
export class AccountIndexComponent implements OnInit, OnDestroy {
  public user!: User;
  public isLoading = true;
  public isRequestingVerification = false;
  public emailRecentlySent = false;

  private snackBarRef: MatSnackBarRef<TextOnlySnackBar> | undefined;
  private readonly emailVerificationQueryParamName = 'verify-email-hash';

  constructor(
    private appConfigurationStore: AppConfigurationStoreService,
    private emailVerificationService: EmailVerificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorSnackBar: ErrorSnackBarService,
    private dialogOpenerService: DialogOpenerService,
  ) {}

  ngOnInit(): void {
    const verifyEmailToken =
      this.activatedRoute.snapshot.queryParams[
        this.emailVerificationQueryParamName
      ];

    this.appConfigurationStore.initialize().then(async () => {
      if (!this.appConfigurationStore.appConfiguration.user) {
        let returnUrl = '/account';
        if (verifyEmailToken) {
          returnUrl += `?${this.emailVerificationQueryParamName}=${verifyEmailToken}`;
        }
        const encodedUrl = encodeURIComponent(returnUrl);
        window.location.assign(`/?returnUrl=${encodedUrl}`);

        return;
      }

      this.user = this.appConfigurationStore.appConfiguration.user;

      if (verifyEmailToken) {
        await this.verifyEmail(verifyEmailToken);
      } else {
        this.isLoading = false;
      }
    });
  }
  ngOnDestroy(): void {
    this.snackBarRef?.dismiss();
  }

  private async verifyEmail(token: string): Promise<void> {
    this.isLoading = true;
    try {
      this.emailVerificationService.verifyEmail(token);
      this.user.isEmailVerified = true;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { verifyEmailToken: null },
      });
      this.errorSnackBar.open(
        null,
        'Your email has been verified.',
        'Dismiss',
        { duration: 5000 },
      );
    } catch (error) {
      this.errorSnackBar
        .open(
          error,
          'There was a problem verifying your email. Please try again.',
          'OK',
        )
        .onAction()
        .subscribe(async () => {
          await this.verifyEmail(token);
        });
    } finally {
      this.isLoading = false;
    }
  }

  public async requestEmailVerificationEmail(): Promise<void> {
    this.isRequestingVerification = true;
    try {
      await this.emailVerificationService.requestEmailVerificationEmail();
      this.errorSnackBar.open(
        null,
        'Email verification email sent.',
        'Dismiss',
        { duration: 5000 },
      );
      this.emailRecentlySent = true;
    } catch (e) {
      if (e instanceof HttpErrorResponse && e.status === 429) {
        this.snackBarRef = this.errorSnackBar.open(
          e,
          'You have tried to send the verification email too many times. Please try again later.',
          'OK',
        );
      } else {
        this.snackBarRef = this.errorSnackBar.open(
          e,
          'There was a problem requesting the email verification email. Please try again.',
          'OK',
        );
      }
    } finally {
      this.isRequestingVerification = false;
    }
  }

  public openAddMultifactorAuthenticationDialog(): void {
    showAddMultifactorAuthenticationDialog(this.dialogOpenerService);
  }
}
